import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'raf/polyfill';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import '@stimulus/polyfills';
import 'intersection-observer';
import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';

window.Rails = Rails;
if (!window._rails_loaded) {
  Rails.start();
}
